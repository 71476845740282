import React, { useEffect, useState } from "react";
//import axios from '../../../node_modules-back/axios';
import axios from 'axios'
//import { useForm } from '../../../node_modules-back/react-hook-form/dist';
import { useForm } from 'react-hook-form';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Form } from 'react-bootstrap';
import { ApiCallPost } from "../../ApiCall/ApiCall";
import { getUrl } from "../../ApiCall/GetUrl";


import FechaNac from "./FechaNac";
import FadeIn from "./FadeIn";
import Footer from "../Footer/Footer";



export default function AddClient2() {
  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: {
      errors
    }
  } = useForm();

  const [validacionCom, setValidacionCom] = useState({ val: false })


  // Actualizador para mostrar errores
  useEffect(() => {
    console.log("Reinicio para errores");
    document.getElementById("finalizar");
    setTimeout(clearErrors, 10);
  }, [])


  const [colorWarning, setColorWarning] = useState(false);
  const handleColorWarning = () => {
    setColorWarning(true)
  }

  // Hooks para almacenar todas las opciones de los select
  const [genders, setGenders] = useState([])
  const [ocupations, setOcupations] = useState([])
  const [formaConocernos, setFormaConocernos] = useState([])

  useEffect(() => {
    axios.get(getUrl() + "get_gender").then(response => {
      setGenders(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
      setGenders(["Hubo un error al obtener la información"])
    });

    axios.get(getUrl() + "get_client_ocupation").then(response => {
      setOcupations(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
    });

    axios.get(getUrl() + "get_client_forma_conocernos").then(response => {
      setFormaConocernos(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
    });

  }, [])


  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [showPassword, setShowPassword] = useState(false);

  const passwordVisibilityChange = () => {
    setShowPassword(!showPassword);
  }

  const [personaMoral, setPersonaMoral] = useState(false);
  const handlePersonaMoralChange = (e) => {
    setPersonaMoral(e.target.checked)
    setValidacionCom({
      val: !validacionCom.val
    })

  }

  const [genreStatus, setGenreStatus] = useState(false);
  const handleGenreStatus = () => {
    setGenreStatus(!genreStatus);
  }

  const [ocupationStatus, setOcupationStatus] = useState(false);
  const handleOcupationStatus = () => {
    setOcupationStatus(!genreStatus);
  }

  const [conocernos, setConocernos] = useState(false);
  const handleConocernosStatus = () => {
    setConocernos(!conocernos);
  }


  const onSubmit = async (data) => {
    console.log(validacionCom.val)
    console.log(data)
    if (validacionCom.val === true) {
      data.id_client_tipo_persona = personaMoral ? 1 : 0;
      // data.client_birthday = "2001-01-01";
      data.dTimeStamp = new Date();
      try {
        const response = await ApiCallPost("/legaltek/post_client", data);
        console.log("Respuesta del servidor: ", response)
        if (response.status === 0) {
          alert("Se ha registrado correctamente")
        }
        try {
          const response = await ApiCallPost("/legalback/client", data);
          console.log("Respuesta del servidor: ", response)
        } catch (error) {
          console.log("Error en la llamada: ", error);
        }

      } catch (error) {
        console.log("Error en la llamada: ", error);
      }
    }

  }

  const onError = (e) => {
    console.log("onError: ", e)
  }

  // Styles
  const izquierdaStyle = {
    borderWidth: '1px',
    borderLeft: '#FBFBFB',
    borderTop: '#FBFBFB',
    backgroundColor: '#FBFBFB',
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'center',
    borderBottomColor: 'gray',
    borderRightColor: 'gray',
    transition: 'border-color 0.2s ease-in-out',
    width: '100%',
    outline: 'none'
  };

  const derechaStyle = {
    borderWidth: '1px',
    borderLeft: '#FBFBFB',
    borderTop: '#FBFBFB',
    backgroundColor: '#FBFBFB',
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'center',
    borderBottomColor: 'gray',
    borderLeftColor: 'gray',
    transition: 'border-color 0.2s ease-in-out',
    width: '100%',
    focus: 'none',
    outline: 'none'
  }

  const labelStyles = {
    fontSize: "25px",
    lineHeight: "30.48px",
    textAlign: 'left',
    display: "block",
    fontWeight: "100",
    paddingBottom: "5px"


  }

  const warning_message = {
    color: 'red',
    fontSize: '15px',
    fontStyle: 'italic',
    display: "block",
    textAlign: "left"

  }


  return (
    <div style={
      {
        maxHeight: '100%',
        overflowY: 'auto',
        backgroundColor: "#FFFFFF"
      }
    }>
      {/* Título */}
      <FadeIn></FadeIn>
      <div className="box-login" id="formulario"
        style={
          {
            zIndex: 20,
            width: '100%',
            backgroundColor: "#FBFBFB",
            boxShadow: "none",
            height: "100%",
          }
        }>
        <div className="d-flex flex-column align-items-center mb-5"
          style={
            {
              borderWidth: 2,
              borderColor: 'black',
              fontFamily: 'Satoshi-Bold',
              overflowX: 'auto'
            }
          }>
          <Form onSubmit={
            handleSubmit(onSubmit, onError)
          }
            className="w-100">
            <div className="row g-0"
              style={
                {
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }
              }>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="nombre"
                    style={
                      {
                        ...labelStyles,
                        display: "block",
                        textAlign: "left"
                      }
                    }>Nombre</label>

                  <input className="form-control rounded-0 border-0 border-bottom border-end" title="Nombre" id="nombre" type="text" {...register("client_name", {
                    required: {
                      value: !personaMoral,
                      message: "Este campo es requerido."
                    },
                    maxLength: {
                      value: 50,
                      message: "El nombre no puede superar los 50 caracteres."
                    },
                    pattern: {
                      value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/i,
                      message: "Solo caracteres alfabéticos."
                    }
                  })}
                    style={izquierdaStyle}
                    disabled={personaMoral} /> {
                    errors?.client_name?.type === "required" && <p style={warning_message}>
                      {
                        errors.client_name.message
                      }</p>
                  }
                  {
                    errors?.client_name?.type === "pattern" && <p style={warning_message}>
                      {
                        errors.client_name.message
                      }</p>
                  }
                  {
                    errors?.client_name?.type === "maxLength" && <p style={warning_message}>
                      {
                        errors.client_name.message
                      }</p>
                  } </div>

              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="correo"
                    style={
                      {
                        ...labelStyles,
                        display: "block",
                        textAlign: "left",
                        paddingLeft: "25px"
                      }
                    }>Correo</label>
                  <input className="form-control rounded-0 border-0 border-bottom border-start" title="Correo" id="correo" type="text" {...register("client_email", {
                    required: "Este campo es requerido.",
                    maxLength: {
                      value: 50,
                      message: "El correo no puede superar los 50 caracteres."
                    },
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Formato de correo no válido."
                    }
                  })}
                    style={derechaStyle} /> {
                    errors?.client_email?.type === "required" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_email.message
                        }</p>
                    )
                  }
                  {
                    errors?.client_email?.type === "maxLength" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_email.message
                        }</p>
                    )
                  }
                  {
                    errors?.client_email?.type === "pattern" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_email.message
                        }</p>
                    )
                  } </div>

              </div>

            </div>
            <div className="row g-0"
              style={
                {
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }
              }>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="telefono"
                    style={
                      {
                        ...labelStyles,
                        display: "block",
                        textAlign: "left"
                      }
                    }>Teléfono</label>
                  <input className="form-control rounded-0 border-0 border-bottom border-end" title="telefono" id="telefono" type="text"
                    style={izquierdaStyle}
                    {...register("client_phone_number", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Solo se permiten números."
                      },
                      maxLength: {
                        value: 15,
                        message: "El número de télefono no puede superar los 15 caracteres."
                      },
                    })} /> {
                    errors?.client_phone_number?.type === "pattern" && (
                      <p style={warning_message}>
                        {
                          errors.client_phone_number.message
                        }</p>
                    )
                  }
                  {
                    errors?.client_phone_number?.type === "maxLength" && (
                      <p style={warning_message}>
                        {
                          errors.client_phone_number.message
                        }</p>
                    )
                  } </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="contrasena"
                    style={
                      {
                        ...labelStyles,
                        paddingLeft: "25px"
                      }
                    }>Contraseña</label>
                  <div className="input-group"
                    style={derechaStyle}>
                    <input className="form-control rounded-0 border-0 border-bottom border-start"
                      style={
                        { background: '#FBFBFB' }
                      }
                      title="contrasena"
                      id="contrasena"
                      type={
                        showPassword ? "text" : "password"
                      }
                      {...register("client_password", {
                        minLength: {
                          value: 8,
                          message: "La contraseña debe tener al menos 8 caracteres."

                        },
                        maxLength: {
                          value: 50,
                          message: "La contraseña no puede tener más de 50 caracteres."
                        },
                        required: "Este campo es requerido.",
                        pattern: {
                          value: passwordPattern,
                          message: "La contraseña debe contener al menos una minúscula, una mayúscula, un número y un caracter especial (@$!%*?&), y tener al menos 8 caracteres de longitud.",
                        }
                      })} />
                    <button type="button" className="btn btn-outline-secondary"
                      onClick={passwordVisibilityChange}
                      style={
                        {
                          borderRadius: '0px',
                          background: '#FBFBFB',
                          border: 'none'
                        }
                      }>
                      {
                        showPassword ? <AiFillEyeInvisible /> : <AiFillEye />
                      } </button>
                  </div>
                  {
                    errors?.client_password?.type === "pattern" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_password.message
                        }</p>
                    )
                  }
                  {
                    errors?.client_password?.type === "required" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_password.message
                        }</p>
                    )
                  }
                  {
                    errors?.client_password?.type === "maxLength" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_password.message
                        }</p>
                    )
                  }
                  {
                    errors?.client_password?.type === "minLength" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.client_password.message
                        }</p>
                    )
                  } </div>
              </div>
            </div>
            <div className="row g-0"
              style={
                {
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }
              }>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="genero"
                    style={labelStyles}>Género</label>
                  <select id="genero" className="form-control rounded-0 border-0 border-end border-bottom" placeholder=""
                    style={
                      {
                        ...izquierdaStyle
                      }
                    }
                    disabled={personaMoral}
                    {...register("id_client_gender", {
                      required: {
                        value: !personaMoral,
                        message: "Este campo es requerido."
                      }
                    })}
                    isClearable={true}
                    onChange={handleGenreStatus}>
                    <option></option>
                    {
                      genders.map(v => <option key={
                        v.id_gender
                      }
                        value={
                          v.id_gender
                        }>
                        {
                          v.gender_name
                        }</option>)
                    } </select>
                  {
                    errors?.id_client_gender?.type === "required" && (
                      <p style={warning_message}>
                        {
                          errors.id_client_gender.message
                        }</p>
                    )
                  } </div>
              </div>
              {/*Sección: Soy persona moral */}
              <div className="col-sm-6">
                <div className="form-group">
                  <div className='d-flex justify-content-between align-items-center'>
                    <label htmlFor="nombre"
                      style={
                        {
                          fontSize: "25px",
                          paddingLeft: "25px",
                          weight: "500",
                          lineHeight: "30.48px",
                          fontStyle: "italic",
                          fontWeight: "10",
                          color: "#3C3C3C"
                        }
                      }>Soy persona moral</label>
                    <input className="form-check-input ml-2" type="checkbox" id="tipoPersona"
                      style={
                        { marginRight: "10px" }
                      }
                      onChange={handlePersonaMoralChange}
                      disabled={genreStatus} />
                  </div>
                  <input className="form-control border-0 rounded-0 border-bottom border-start"
                    style={
                      {
                        ...derechaStyle,
                        fontStyle: "italic",
                        marginTop: "5px"
                      }
                    }
                    {...register("client_name_moral", {
                      required: {
                        value: personaMoral,
                        message: "Este campo es obligatorio."
                      }
                    })}
                    placeholder="Nombre"
                    disabled={
                      !personaMoral
                    } />
                </div>
              </div>
            </div>
            <div className="row g-0"
              style={
                {
                  paddingTop: "10px",
                  paddingBottom: "20px"
                }
              }>
              <div className="col-sm-6">
                <div className="form-group">
                  <FechaNac control={control}
                    errors={errors}
                    name="client_birthday"
                    isRequired={
                      !personaMoral
                    }
                    colorW={colorWarning}
                    labelName="Fecha de Nacimiento"
                    styleLabel={labelStyles}
                    validacionCom={validacionCom}></FechaNac>


                </div>

              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="client_ocupation"
                    style={
                      {
                        ...labelStyles,
                        paddingLeft: "25px"
                      }
                    }>Ocupación</label>
                  <select id="client_ocupation" className="form-control rounded-0 border-0 border-start border-bottom" placeholder=""
                    style={derechaStyle}
                    {...register("id_client_ocupation", {
                      required: {
                        value: true,
                        message: "Este campo es requerido."
                      }
                    })}
                    onChange={handleOcupationStatus}>
                    <option></option>
                    {
                      ocupations.map(v => <option key={
                        v.id_ocupation
                      }
                        value={
                          v.id_ocupation
                        }>
                        {
                          v.ocupation
                        }</option>)
                    } </select>
                  {
                    errors?.id_client_ocupation?.type === "required" && (
                      <p style={
                        {
                          ...warning_message,
                          paddingLeft: "25px"
                        }
                      }>
                        {
                          errors.id_client_ocupation.message
                        }</p>
                    )
                  } </div>

              </div>

            </div>
            <div className="row g-0"
              style={
                { paddingTop: "10px" }
              }>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="forma_conocernos"
                    style={labelStyles}>¿Cómo conociste LegalTek?</label>
                  <select id="forma_conocernos" className="form-control rounded-0 border-0 border-end border-bottom" placeholder=""
                    style={
                      {
                        ...izquierdaStyle,
                        width: "90%"
                      }
                    }
                    {...register("id_client_forma_conocernos", {
                      required: {
                        value: true,
                        message: "Este campo es requerido."
                      }
                    })}
                    isClearable={true}
                    onChange={handleConocernosStatus}>
                    <option></option>
                    {
                      formaConocernos.map(v => <option key={
                        v.id_forma_conocernos
                      }
                        value={
                          v.id_forma_conocernos
                        }>
                        {
                          v.forma_conocernos
                        }</option>)
                    } </select>
                  {
                    errors?.id_client_forma_conocernos?.type === "required" && (
                      <p style={warning_message}>
                        {
                          errors.id_client_forma_conocernos.message
                        }</p>
                    )
                  } </div>

              </div>
              <div className='col-sm-6 d-flex align-items-end'>
                <div className='form-group w-100'>
                  <button type='submit' className='btn btn-primary btn-block rounded-0'
                    style={
                      {
                        width: '100%',
                        position: 'relative',
                        background: '#fBfBfB',
                        color: '#0171E3',
                        border: '1px solid #',
                        padding: '8px 16px',
                        transition: 'all 0.3s ease',
                        overflow: 'hidden'
                      }
                    }
                    onMouseOver={
                      (e) => {
                        e.target.style.border = '1px solid #c0c0c0';
                        e.target.style.color = '#c0c0c0';
                      }
                    }
                    onMouseOut={
                      (e) => {
                        e.target.style.border = '1px solid #007bff';
                        e.target.style.color = '#007bff';
                      }
                    }
                    onClick={handleColorWarning}>Finalizar</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Footer oscuro={true}></Footer>

    </div>
  )
}
