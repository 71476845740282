import React, { useState, useRef, useEffect } from 'react'
import select from 'react-select'
import { Button, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import PasswordInput from './PasswordInput';

import Carrera from './Carrera';
import Servicios from './Servicios';
import Especialidades from './Especialidades';
import Estados from './Estados';

//import { useForm } from "../../../node_modules-back/react-hook-form/dist";
import Footer from '../Footer/Footer';

import { useForm } from "react-hook-form";
// import './styles.css'
import "../../styles/AddLawer2Styles.css"
import FadeIn from './FadeInLawyer.js';
import axios from "axios";
import { getUrl } from "../../ApiCall/GetUrl";



import LoadPhoto from './LoadPhoto.js';
import Fechas from './Fechas.js';
import Languages from './Languages.js';


export default function AddLawer2() {


  const data = [

    {
      name : "licenciatura" ,
      univ : "" ,
      educacion : ""

   } ,
    {
      name : "maestria" ,
      univ : "" ,
      educacion : ""

    } ,
    {
      name : "doctorado" ,
      univ : "" ,
      educacion : ""

    } ,
    {
      name : "diplomado" ,
      univ : "" ,
      educacion : ""

    } ,


  ]

  

  const [showPassword, setShowPassword] = useState(false);
  //const [nombre,setNombre] = useState("")
  //const [correo,setCorreo] = useState("")
  //const [telefono,setTelefono] = useState("")
  //const [password,setPassword] = useState("") // Esta pendiente password
  const [descripcion, setDescripcion] = useState("")
  const [despacho, setDespacho] = useState("")
  const [posicion, setPosicion] = useState("")
  const [ubicacion, setUbicacion] = useState("")
  const [matricula, setMatricula] = useState("")

  const [theLoadPhoto,setTheLoadPhoto] = useState( false ) // Por default no se pone la carga de la photo
  const [image,setImage] = useState( null ) ;
  const [image64,setImage64] = useState( null )
  const [languages,setLanguages] = useState([])

  const [licenciatura,setLicenciatura] = useState( null)
  const [maestria,setMaestria] = useState( null)
  const [doctorado,setDoctorado] = useState( null)
  const [diplomado,setDiplomado] = useState( null)
  const [espPrecio,setEspPrecio] = useState( null )
  const [servicio,setServicio] = useState( false )
  const [rfc,setRfc] = useState( null )
  const [estado,setEstado] = useState( null )



  function postData( data )
  {

    axios.post(getUrl() +"lawyres" ,data )
    .then((result) =>{

    })
    .catch((err) =>{
      console.log( "error = " + err )
    })

  }


  useEffect(() =>{


    //console.log( "image64 = " + image64 )

  },[image64])

  function setImagePrev( e )
  {
    setImage( e ) ;

    convertToBase64( e ) ;

    //console.log( "base54 = " , b64 )
  }

  function convertToBase64( e )
  {
    const file = e

    const reader = new FileReader()


    reader.onloadend = () => {
      setImage64( reader.result.toString())
    }

    reader.readAsDataURL(file)
  }


  function onEspPrecio( val )
  {

    console.log( "onEspprecio = " , val ) ;

    setEspPrecio( val )
  }


  function onInputChange( val )
  {
    console.log( "onInputChange ** " , val )

    if ( val.name === "licenciatura")
    {



      if ( val.univ.trim() === "" || val.educacion.trim() === "" )
      {
        setLicenciatura( null )
        return ;
      }

      setLicenciatura( val )
      return ;
    }


    if ( val.name === "maestria")
    {

      if ( val.univ.trim() === "" || val.educacion.trim() === "" )
        {
          setMaestria( null )
          return ;
        }

        setMaestria( val )
        return ;
    }

    if ( val.name === "doctorado")
      {

        if ( val.univ.trim() === "" || val.educacion.trim() === "" )
          {
            setDoctorado( null )
            return ;
          }

          setDoctorado( val )
          return ;
      }

      if ( val.name === "diplomado")
        {
          if ( val.univ.trim() === "" || val.educacion.trim() === "" )
            {
              setDiplomado( null )
              return ;
            }

            setDiplomado( val )
            return ;
        }
    

        console.log( "No lo encuento ************")
    
  }

  function setTheLoadPhotoPrev( val )
  {
    setTheLoadPhoto( val )

    console.log( "Se programa timer")

    setTimeout( () =>{

      setTheLoadPhoto( false ) ;

    } , 10000 )

  }

  

  /*
{
  "lawyer_email": "test1@gmail.com", // correo ( 2 )
  "lawyer_password": "A1234$", // password ( 5 )
  "lawyer_name": "test one", // nombre ( 1 )
  "lawyer_birthday": "2024-08-12", // Falta *********
  "lawyer_photograph": "aaaaaaaaaaaaaaaaaaaaaaaa", ( 3 )
  "lawyer_status": 1,
  "lawyer_matricula": "aaaaa", // matricula
  "lawyer_begin": "2024-08-12",
  "lawyer_working_begin_date": "2024-08-12", // Falta **********
  "lawyer_services": 1,
  "lawyer_cv": "asjfasfsdfaf", // nulo
  "lawyer_linkedin": "sdfasdfasf", // nulo 
  "lawyer_description": "asfahsfahfasdfj", ( 6 )
  "lawyer_rfc": "afajsfasf",
  "lawyer_status_legaltek": 1,
  "id_lawyer_costo": 5,
  "lawer_phone": "fñasdjfkalsdjf", // telefono ( 4 )
  "languages": [
    1,
    2
  ],
  "especialidades": [
    1,
    2,
    3
  ],
  "grado_academico": [
    {
      "id_academic_degree": 1,
      "date_degree": "2024-08-13",
      "grado_academico": 1
    }
  ],
// Todo este JSON es opcional solo en caso de que trabaje en un despacho ¿
  "despacho": {
    "name_despacho": "ajñdflajfl", // despacho
    "location": "ajdñkljalfkja", // ubicacion
    "id_position": 1 // posicion
  }
}


  */


function verifyData( from )
{

  var eMail = getValues( "correo")


  if ( eMail === null || eMail === "" )
  {
    console.log( "No se capturo correo")
    return ;
  }


  var password = getValues( "password")


  if ( password === null || password === "" )
  {
    console.log( "No se capturo password")
    return ;
  }


  var nombre = getValues( "nombre")

  console.log( "nombre == " + nombre )


  if ( nombre === null || nombre === "" )
  {
    console.log( "No se capturo nombre")
    return ;
  }


  // fInicio , birthday


  var fInicio = getValues( "fInicio")

  console.log( "fInicio == " + fInicio )


  if ( fInicio === null || fInicio === "" )
  {
    console.log( "No se capturo fInicio")
    return ;
  }

  var birthday = getValues( "birthday")

  console.log( "birthday == " + birthday )


  if ( birthday === null || birthday === "" )
  {
    console.log( "No se capturo birthday")
    return ;
  }


  // lawyer_photograph


  if ( image64 === null )
  {
    console.log( "No se ha capturado la imagen")
    return ;
  }


  var matricula = getValues( "matricula")

  console.log( "matricula == " + matricula )


  if ( matricula === null || matricula === "" )
  {
    console.log( "No se capturo matricula")
    return ;
  }


  var descripcion = getValues( "descripcion")

  console.log( "descripcion == " + descripcion )


  if ( descripcion === null || descripcion === "" )
  {
    console.log( "No se capturo descripcion")
    return ;
  }

  if ( estado === null )
  {
    console.log( "No se ha seleccionado estado")

    return ;
  }


  var states = [] ; states.push( estado ) // Aqui ponemos el arreglo de estados


  if ( espPrecio === null || espPrecio.especialidad === null || espPrecio.precio === null  )
  {
    console.log( "No se capturo especialidad precio ")

    return ;
  }

  if ( licenciatura === null )
  {
    console.log( "Al menos se debe capturar la licenciatura")

    return ;
  }

  

/*
  for (var i = 0; i < data.academic_degrees.length; i++) {
    await insertAcademicDegree(id, data.id_academic_degree[i], data.name_degree[i], data.institution_degree[i], data.date_degree[i])

  }

*/


  var gradoAcademico = []

  gradoAcademico.push( licenciatura ) ;


  if ( maestria === null )
  {
    console.log( "Al menos se debe capturar la maestria")

    return ;
  }
  else{
    gradoAcademico.push( maestria ) ;
  }


  
  
  if ( doctorado === null )
  {
    console.log( "Al menos se debe capturar la doctorado")

    return ;
  }
  else{
    gradoAcademico.push( doctorado ) ;
  }


  if ( diplomado === null )
    {
      console.log( "Al menos se debe capturar la diplomado")
  
      return ;
    }
    else{
      gradoAcademico.push( diplomado ) ;
    }
    
  



  const data = {
    lawyer_email : eMail ,
    lawyer_password : password ,
    lawyer_name : nombre ,
    lawyer_working_begin_date : fInicio ,
    lawyer_birtday : birthday ,
    lawyer_photograph : image64 , // Aqui la imagen en base 64
    lawyer_status : 1 , // Checar que es 1
    lawyer_matricula : matricula ,
    lawyer_begin : new Date() ,
    lawyer_services : (servicio === true  ? 2 : 1 ), 
    lawyer_descripcion : descripcion ,
    lawyer_rfc : rfc ,
    lawyer_states : states ,
    lawyer_status_legaltek : 1 ,
    id_lawyer_costo : espPrecio.precio ,
    especialidades : espPrecio.especialidad ,
    grado_academico : gradoAcademico ,

  }

}

  function onError( errors )
  {
      console.log( "onError " , errors)


      console.log( "getValues birthday= " , getValues("birthday"))
      console.log( "getValues licenciatura = " , getValues("licenciatura"))


      if ( licenciatura === null ) // maestria , doctorado , diplomado
      {
        console.log( "No se capturo licenciatura")
      }
      else{
        console.log( "Si se capturo licenciatura")
      }


      if ( maestria === null )
      {
        console.log( "No se capturo maestria")
      }
      else{
        console.log( "Si se capturo maestria")
      }


      if ( doctorado === null )
      {
        console.log( "No se capturo doctorado")
      }
      else{
        console.log( "Si se capturo doctorado")
      }
  
      if ( diplomado === null )
      {
        console.log( "No se capturo diplomado")
      }
      else{
        console.log( "Si se capturo diplomado")
      }

      console.log( "languages.length = " + languages.length )
  
      if ( image === null )
      {
        console.log( "No se ha cargado la imagen")
      }

      verifyData( "onError")
  }

  useEffect(() =>{

    console.log( "Cambio de imagen")

  } ,[image])

  // register, handleSubmit, clearErrors, control, formState: { errors }
  // Actualizador para mostrar errores
  useEffect(() => {
    console.log("Reinicio para errores")
    document.getElementById("finalizar")
    setTimeout(clearErrors, 10);
  }, [])

  const [posicionArr, setPosicionArr] = useState([]);
  useEffect(() => {
    axios.get(getUrl() + "get_lawyer_position").then(response => {
      setPosicionArr(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
      setPosicionArr(["Hubo un error al obtener la información"])
    });
  }, [])



  const {
    register,
    formState: { errors },
    handleSubmit,
    //setError ,
    watch,
    clearErrors,
    getValues ,
    control
  } = useForm(/*{
        defaultValues: {
          legal: { 
            nombre : "" , 
            correo: "" ,
 
            
          } 
        }
      }*/);


  console.log(watch('correo'));


  function handleSub(e) // Lo recortamos para evitar redundancia
  {

    //e.preventDefault() ;
    console.log("handleSub");

    if ( image === null )
    {
      console.log( "No se ha cargado la imagen") 
    }
    else{
      console.log( "Si tenemos la imagen cargada")
    }

    verifyData( "handleSubmit")

  }



  // const togglePasswordVisibility = () => {
  //     setShowPassword(!showPassword);
  //   };
  return (
    <>
      <div className='screen'>
        <div>
          <FadeIn></FadeIn>
        </div>
        <div className='box-login box-login-aux ' id="theDiv" >

          <div className='d-flex flex-column align-items-center espacio-box-login-form'>


            <div className='register-gray-box'> 

              <h2 className='mt-3 registrate-text'>
                <span>Regístrate</span> </h2>

              <div className='mt-4 text-center' >
                <p className="promocion-text">Y recibe tus primeros dos clientes de forma completamente gratuita.</p>
              </div>


            </div>

            <div className='display-label-none  mt-5 w-100 fondo-form' >

              <Form onSubmit={handleSubmit(handleSub,onError)} className="form-tag">

                {/* {
                        nombre !== "" && (
                            <p>Cambio nombre</p>
                        )
                    }
                     */}

                <div className='row g-0 mb-3 pt-3'>

                  <div className='col-sm-6'>
                    <div className="form-group">
                      <label className={"estilo_label"} htmlFor="nombre">Nombre </label>
                      <input type="text" className={"form-control border-0 border-bottom estilo_input"} id="nombre" title='Nombre de Abogado'

                        {...register('nombre', { required: "Nombre campo requerido" })}
                      />

                    </div>
                  </div>

                  <div className='col-sm-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label derecha"} htmlFor="correo">Correo Electrónico</label>
                      <input type="email" className={"form-control  border-0 border-bottom border-start estilo_input derecha"} id="correo" title='Correo de Abogado'
                        {...register('correo', { required: "Correo , campo requerido" })}
                      />

                    </div>
                  </div>

                </div>

                <div className='row mb-3 pt-3'>
                  <div className='col-6 errtype'>
                    {errors.nombre && (<p >{errors.nombre.message}</p>)}
                  </div>
                  <div className='col-6 errtype'>
                    {errors.correo && (<p >{errors.correo.message}</p>)}
                  </div>

                </div>

 


                <div className='row g-0 mb-3 pt-3'> {/* Esta es la carga de la foto */}

                  <div className='col-3 text-center foto-div' >


                    <p className={"estilo_label"}>Fotografía</p>



                  </div>

                  <div className='col-3'>


                  {
                    theLoadPhoto && (
                      <div className="foto-conditional-div">
                          <LoadPhoto showNormal={(val)=>setTheLoadPhoto(val)} setSelectedImage={(val)=>setImagePrev(val)}></LoadPhoto>
                      </div>

                      
                    )
                  }

                  </div>

                  <div className='col-6 mt-2 d-flex flex-column align-items-center'>

                    <div className='load-photo' onClick={()=>setTheLoadPhotoPrev(true)}>

                      {
                        image !== null && (                      
                        <img
                        alt="not found"
                        
                        width={"100%"} height={"100%"} 

                        resizeMode='contain'
                        
                        src={URL.createObjectURL(image)}
                      />


                        )
                      }


                    </div>

                  </div>

                </div>

                

                




                <div className='row g-0 mb-3 pt-5'>

                  <div className='col-sm-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label"} htmlFor="telefono">Teléfono </label>
                      <input type="text" className={"form-control  border-0 border-bottom estilo_input "} id="telefono" title='Teléfono de Abogado'
                        {...register('telefono', { required: false })}
                      />

                    </div>
                  </div>

                  <div className='col-sm-6 password-input'>
                    {/* <div className="form-group"   >
                                <label htmlFor="password">Contraseña</label>
                                <input type="password" className="form-control" id="correo"  title='Contraseñs'
                                placeholder="Contraseña" style={{padding:'5px'}}
                                onChange={(n) => setPassword(n)} />
                                
                            </div> */}
                    {/* <div className="form-group">
                                <label htmlFor="password">Contraseña </label>
                                <input className='w-75 form-control' id='password'
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter your password"
                                />
                                <button onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>                         */}

                    <div className='pt-0'>
                      <PasswordInput register={register} />
                    </div>


                  </div>

                </div>

                <div className='row mb-3 pt-3'>
                  <div className='col-6 errtype'>
                    {errors.telefono && (<p >{errors.telefono.message}</p>)}
                  </div>
                  <div className='col-6 errtype'>
                    {errors.password && (<p >{errors.password.message}</p>)}
                  </div>

                </div>

                <div className='row g-0 mt-4 mb-3'>

                  <div className='col-12'>


                    <div className="form-group"   >
                      <label className={"estilo_label mb-3"} hhtmlFor="descripcion">Descripción Profesional </label>
                      <textarea className="form-control description-input" id="descripcion" title='Descripción Profesional'

                        // Cómo centrar este textarea
                        

                        {...register('descripcion', { required: "Descripción profesional , campo requerido" })}


                      />
                    </div>

                  </div>

                </div>

                <div className='row g-0 mb-3 pt-5'>

                  <div className='col-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label"} htmlFor="despacho">Despacho</label>

                      <input type="text" className={"form-control  border-0 border-bottom estilo_input"} id="despacho" title='Despacho'
                      {...register('despacho', { required: false })}
                        onChange={(n) => setDespacho(n)} />

                    </div>

                  </div>

                  <div className='col-6'>

                    <div className="form-group"   >
                      <label className={"estilo_label derecha"} htmlFor="posicion">Posición</label>
                      <select type="text" placeholder='' className={"form-control  border-0 border-bottom border-start estilo_input derecha"}
                      {...register('posicion', { required: true })}
                      id="posicion" title='Posicion'>
                      
                        <option></option>
                        {posicionArr.map(v => <option key={
                          v.id_position
                        } value={
                          v.id_position
                        }>

                          {v.position}
                        </option>
                        )}
                      </select>

                    </div>


                  </div>


                </div>


                <div className='row g-0 mb-3 pt-5'>

                  <div className='col-6'>
                    <div className="form-group"   >
                      <label className={"estilo_label"} htmlFor="ubicacion">Ubicación</label>
                      <input type="text" className={"form-control  border-0 border-bottom estilo_input "} 
                      {...register('ubicacion', { required: true })}
                      id="ubicacion" title='Ubicacion'
                        onChange={(n) => setUbicacion(n)} />

                    </div>

                  </div>

                  <div className='col-6'>

                    <div className="form-group"   >
                      <label className={"estilo_label derecha"} htmlFor="matricula">Matrícula</label>
                      <input type="text" 
                        {...register('matricula', { required: true })}
                        className={"form-control  border-0 border-bottom border-start estilo_input derecha"} id="matricula" title='matricula'
                        onChange={(n) => setMatricula(n)} />

                    </div>


                  </div>


                </div>

                <div className="fechas-input">
                  <Fechas control={control} errors={errors} name2={"fInicio"} name3={"birthday"}></Fechas>
                </div>
                

                <div className='mt-4'>

                  <p className={"subtitulos"}>Educación</p>

                </div>

                <Carrera title={'Licenciatura'} prefix={'lic'} inputChange={onInputChange}
                  control={control} name2={'licenciatura'} errors={errors} isRequired={true} />
                <Carrera title={'Maestria'} prefix={'mae'} inputChange={onInputChange}
                  control={control} name2={'maestria'} errors={errors} isRequired={true} />

                <Carrera title={'Doctorado'} prefix={'doc'} inputChange={onInputChange}
                  control={control} name2={'doctorado'} errors={errors} isRequired={true} />

                <Carrera title={'Diplomado'} prefix={'dip'} inputChange={onInputChange}
                  control={control} name2={'diplomado'} errors={errors} isRequired={true} />


                <Languages setLanguages={(val)=>setLanguages(val)}></Languages>


                <Servicios setServicio={(val) => setServicio(val)} />
                <Especialidades setEpecialPrecio={onEspPrecio} />
                <Estados setRfc={setRfc} setEstado={setEstado} onFinalizar={handleSubmit(handleSub) } />



              </Form>

            </div>

          </div>



        </div>
        <Footer oscuro={true}></Footer>

      </div>
    </>
  )
}
