import React, { useState } from 'react';
//import { useController } from '../../../node_modules-back/react-hook-form/dist';
import { useController } from 'react-hook-form';

const FechaNac = ({ control, name, errors, isRequired }) => {
    const {
        field: { onChange: onInputChange, value },
    } = useController({
        name,
        control,
        defaultValue: '',
    });

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const handleDayChange = (e) => {
        const { value } = e.target;
        setDay(value);
        updateFechaNac(value, month, year);
    };

    const handleMonthChange = (e) => {
        const { value } = e.target;
        setMonth(value);
        updateFechaNac(day, value, year);
    };

    const handleYearChange = (e) => {
        const { value } = e.target;
        setYear(value);
        updateFechaNac(day, month, value);
    };

    const updateFechaNac = (day, month, year) => {
        const formattedDate = `${year}-${month}-${day}`;
        onInputChange(formattedDate);
    };

    const validateDate = () => {
        if (!value && isRequired) {
            return <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>Este campo es requerido.</p>;
        }
        
        if(day === '' && month === '' && year === ''){
            return <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>Este campo es requerido.</p>;
        }

        if (value) {
            const currentDate = new Date();
            const minDate = new Date('1900-01-01');
            const eighteenYearsAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 18));
            const selectedDate = new Date(value);

            if (selectedDate < minDate) {
                return <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>El año no puede ser antes del año 1900.</p>;
            } else if (selectedDate > eighteenYearsAgo) {
                return <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>Debe tener al menos 18 años para poder registrarse.</p>;
            }

            const year = selectedDate.getFullYear();
            const month = selectedDate.getMonth() + 1;
            const day = selectedDate.getDate();

            if (day > 31 || day < 1) {
                return <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>Día inválido para el mes seleccionado.</p>;
            }

            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
            const daysInMonth = {
                1: 31, 2: isLeapYear ? 29 : 28, 3: 31, 4: 30, 5: 31, 6: 30,
                7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31
            };

            if (day > daysInMonth[month]) {
                return <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>Día inválido para el mes seleccionado.</p>;
            }
        }

        return null;
    };

    return (
        <div className="form-group" style={{ width: '100%' }}>
            <div className="input-group " style={{marginTop:'0px'}}>
                <input 
                    type="text"
                    name="day"
                    placeholder="DD"
                    maxLength="2"
                    className="form-control rounded-0 border-0 border-bottom border-end"
                    style={{
                        ...inputStyle,
                        width: '20%',
                        textAlign: 'center',
                    }}
                    value={day}
                    onChange={handleDayChange}
                />
                <input 
                    type="text"
                    name="month" 
                    placeholder="MM"
                    maxLength="2"
                    className="form-control rounded-0 border-0 border-bottom border-end"
                    style={{
                        ...inputStyle,
                        width: '20%',
                        textAlign: 'center',
                    }}
                    value={month}
                    onChange={handleMonthChange}
                />
                <input 
                    type="text"
                    name="year"
                    placeholder="AAAA"
                    maxLength="4"
                    className="form-control rounded-0 border-0 border-bottom"
                    style={{
                        ...inputStyle,
                        width: '20%',
                        textAlign: 'center',
                    }}
                    value={year}
                    onChange={handleYearChange}
                />
            </div>
            {/* {errors.client_birthday && (
                <p style={{ color: 'red', fontSize: '11px', fontStyle: 'italic' }}>
                    {errors.client_birthday.message}
                </p>
            )} */}
            {validateDate()}
        </div>
    );
};

const inputStyle = {
    borderWidth: '1px',
    backgroundColor: '#FbfbFb',
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'center',
    borderBottomColor: 'gray',
    borderRightColor: 'gray',
    transition: 'border-color 0.2s ease-in-out',
    marginBottom: '15px',
    paddingRight: '2px',
    marginRight: '2px',
    width: '100%',
    outline: 'none',
};

export default FechaNac;
