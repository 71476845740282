import React from "react";
//import { useInView } from "../../../node_modules-back/react-intersection-observer/dist/index.d.mts";
import { useInView } from "react-intersection-observer";
import './FadeIn.css';

export default function FadeIn() {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 1 });

  return (
    <div>
        <h1 style={{
            padding: "100px"
        }}></h1>
      <h1
        ref={ref1}
        className={`fade-in ${inView1 ? 'visible' : ''}`}
        style={{
          color: "#000000",
          textAlign: 'center',
          padding: '100px',
          fontSize: "50px",
        }}
      >
        <b>La justicia jamás había sido tan fácil.</b>
      </h1>
      <h1 style={{
        padding: "50px"
      }}>

      </h1>
      <h1
        ref={ref2}
        className={`fade-in ${inView2 ? 'visible' : ''}`}
        style={{
          textAlign: 'center',
          color: "#000000",
          padding: '100px',
          fontSize: "50px",
          marginBottom: "200px"
        }}
      >
        <b>Accede a LegalTek.</b>
      </h1>
    </div>
  );
}
