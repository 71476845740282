import "./CuadroCentral.css"
export default function CuadroCentral() {


  const textoStyle = {
    fontSize: "40px",
    marginLeft: "30px",
    marginRight: "30px",
    paddingBottom: "100px",
    paddingTop: "100px",
    textAlign: "left"
  }

  return (
    <>
      <div className="d-flex justify-content-center" style={{ background: "black", borderRadius: "30px", margin: "10px", width: "95%", height: "565px" }}>
        <div className="col-6">
          <div className="d-flex justify-content-left" style={{ backgroundColor: "#FFFFFF", marginLeft: "50px", height: "100%", width: "80%" }}>
            <p style={{ fontSize: "23px", marginLeft: "50px", marginRight: "50px", paddingTop: "120px", paddingBottom: "100px", textAlign: "left" }}>En este apartado, podrás seleccionar cualquiera de los productos que ofrecemos. Desde la búsqueda de un abogado que te pueda asesorar en temas de alta complejidad, hasta la facilidad de crear documentos legales por tu cuenta, mediante un proceso guiado, que garantiza que tus necesidades sean atendidas de manera precisa y profesional. </p>
          </div>
        </div>
        <div className="col-6 d-flex flex-column justify-content-center align-items-center">
          <div className="row-0 justify-content-left align-items-center" style={{ marginBottom: "80px" }}>
            <button className="buttonStyle">Busco a un Abogado</button>
          </div>
          <div className="row-0 justify-content-left aling-items-center" style={{ marginTop: "80px" }}>
            <button className="buttonStyle">Busco un Documento</button>
          </div>
        </div>

      </div>
    </>
  )
}
