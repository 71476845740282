
import React , {useEffect , useState }from 'react'

//import AddLawyerDate from './AddLawyerDate'
//import AddLawyerDate from './AddLawyerDate'
import FechaNac from './FechaNac'
import "../../styles/AddLawer2Styles.css"

import { useController } from 'react-hook-form';


export default function Carrera( { title , prefix , control , errors , name2 , isRequired , inputChange}) { // Tag lic , mae , uni
    
    //const [titletag,setTitleTag] = useState( '')
    const [name3,setName] = useState( "")
    const [univ,setUniv] = useState( "")
    const [educacion,setEducacion] = useState( "")
    const [univfecha,setUnivFecha] = useState( "")


    // const {
    //     field: { onChange: onInputChange, value },
    // } = useController({
    //     name3,
    //     control,
    //     defaultValue: '',
    // });

    function getAcademic( grade ) // licenciatura , maestria , doctorado , diplomado
    {
        if ( grade === "licenciatura")
        {
            return 1 ;
        }
        if ( grade === "maestria")
        {
            return 2 ;
        }
        if ( grade === "doctorado")
        {
            return 3 ;
        }
        if ( grade === "diplomado")
        {
            return 4 ;
        }
            
    }

    useEffect(() =>{

        var result = {
            id_academic_degree : getAcademic(name3) , // id_academic_degree
            //univ : univ , // Universidad donde se estudio
            //name_degree : educacion , // Ing en computacion ( nombre de la carrera o especialidad) *****
            institution_degree : univ ,// Ing en computacion
            date_degree : new Date() ,

        }


        console.log( "onInputChange " , result )

        //onInputChange( result )
        inputChange( result ) ;


    } ,[univ,educacion])

    function onHandleName(  )
    {
        var value = document.getElementById( `${name3}`).value

        console.log( "value = " + value )

        setEducacion( value )
    }

    function onHandleUniv()
    {
        var value = document.getElementById( `univ`).value

        console.log( "Univ = " + value )
        
        setUniv( value )
    }


    useEffect(() => {

        console.log( "setName = " , name3 )

        setName( name2 )
        //setUniv( prefix  +  'univ'  ) // maestriauniv
        //setUnivFecha( prefix + "fecha") // mestria



    } , [])
  return (

    
    <div className='row g-0'>

        <div className='col-4'>
            <div className="form-group"   >
                {/* <label htmlFor={name}>{title}</label> */}
                <input type="text" onChange={ onHandleName} 
                    className={"form-control  border-0 border-bottom estilo_input "} id={name3}  title={title}
                placeholder={title} />
                
            </div>

        </div>

        <div className='col-4'>

            <div className="form-group"   >
                <input type="text"  onChange={ onHandleUniv}
                    className={"form-control  border-0 border-bottom border-start estilo_input"} id='univ'  title='Universidad'
                placeholder='Universidad'
                />
                
            </div>

        </div>


        <div className='col-4'>

            <div className="form-group mb-1" >
                <FechaNac  control={control} errors={errors} name={name2} isRequired={false}  />
                {/*<label style={{fontStyle:'italic' , fontWeight : '100'}}  htmlFor={univfecha}>Fecha</label>
                <input type="text" className="form-control  border-0 border-bottom border-start" id={univfecha}  title='Fecha'
                style={{padding:'5px' ,  backgroundColor : '#F8F8F8' ,borderRadius : '0px' }}
                /> */}
                
            </div>

        </div>

    </div>



  )
}
