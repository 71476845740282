import React , {useState,useEffect} from 'react'
import "../../styles/AddLawer2Styles.css"

export default function Srvicios ( {setServicio} ) {

    const [consulta,setConsulta] = useState( false )

    function onConsulta( )
    {
        setConsulta( !consulta )
    }

    useEffect(() =>{

        setServicio( consulta ) ;

    },[consulta])

  return (

    <>
    <div className='mt-4'>
        <p className={"subtitulos"}>Servicios a ofrecer el LegalTek</p>
    </div>

    <div className='row g-0'>
        <div className='col-6 border-0 border-bottom' style={{border : '2px solid black'}}>

            <p className={"subtitulos dos"}>Cita</p>

        </div>
        <div className='col-6 border-0 border-bottom border-start' style={{border : '2px solid black'}}>

            <p className={"subtitulos dos"}>Consulta</p>

        </div>
    </div>

    <div className='row g-0'>

        <div className='col-6 border-0 border-bottom ' style={{border : '2px solid black', height :'400px'}} >

            <p className={"estilo_texto"}>Recibe citas de todos los que necesiten de tus servicios conforme a tu horario.</p>

            <br></br>

            <div   className=' pb-2   text-center' >
                <button className={"estilo_boton"}>Inscribir</button>
            </div>


        </div>

        <div className='col-6 border-0 border-bottom border-start' style={{border : '2px solid black' , height :'400px'}}>

            <p className={"estilo_texto"}>Ayuda a las personas a resolver sus dudas jurídicas en videollamada, cobrando por hora.</p>
            <div   className='mt-4  pb-2  text-center' >
                <button className={"estilo_boton"} onClick={onConsulta}>Inscribir</button>
            </div>
        </div>





    </div>
    
    </>
  )
}
