
export function getUrl()
{

    return "http://localhost:3005/legalback/" // Para backeend local

    // http://localhost:3004/livefactura/

    //return "https://boxy.currenmexico.com/legalback/"

}
