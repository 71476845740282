import React, { useEffect, useState } from "react";
//import axios from '../../../node_modules-back/axios';
import axios from 'axios'
//import { useForm } from '../../../node_modules-back/react-hook-form/dist';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { getUrl } from "../../ApiCall/GetUrl";


import Footer from "../Footer/Footer";
// Importación de los estilos
import "../../styles/ConsultaExpress.css"


const onSubmit = async (data) => {
}

const onError = (e) => {
  console.log("onError: ", e)
}


export default function ConsultaExpress() {
  const [especialidades, setEspecialidad] = useState([])
  const [lugares, setLugar] = useState([])
  const [costos, setCosto] = useState([])


  const [valueEspecialidad, setValueEspecialidad] = useState(-1);
  const [boolDescription, setBoolDescription] = useState(true);

  const changeValue = (n)=>  {
    console.log("valueEspecialidad: ", valueEspecialidad);
    setValueEspecialidad(n);
    n === -1 ? setBoolDescription(true): setBoolDescription(false);
  }


  useEffect(() => {
    axios.get(getUrl() + "get_especialidades_express").then(response => {
      setEspecialidad(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
      setEspecialidad(["Hubo un error al obtener la información"])
    });

    axios.get(getUrl() + "get_costos_express").then(response => {
      setCosto(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
    });

    axios.get(getUrl() + "get_states_express").then(response => {
      setLugar(response.data);
      console.log("Este es el response.data: ", response.data)
    }).catch(error => {
      console.log("Error fetching data: ", error)
    });

  }, [])


  const [colorWarning, setColorWarning] = useState(false);
  const handleColorWarning = () => {
    setColorWarning(true)
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    formState: {
      errors
    }
  } = useForm();

  return (
    <>
      <div className="mt-5 container contenedor">
        <h1 className="mb-3">Consulta Express</h1>
        <Form onSubmit={handleSubmit(onSubmit, onError)} id="formulario">
          <div className="row g-0 d-flex">
            <div className="col-sm-6 m-auto">
              <div className="form-group">
                <label htmlFor="especialidad" className="label-style">*Especialidad</label>
                <select onClick={(e) => changeValue(Number(e.target.value))} id="especialidad" className="form-control rounded-0 border-0 border-end border-bottom productos-select"
                  {...register("especialidad", {
                    required: {
                      value: true,
                      message: "Este campo es requerido."
                    }
                  })}
                  isClearable={true}

                >
                  <option value={-1} ></option>

                    {
                      especialidades.map(v => <option onChange={() => changeValue(v.id_especialidad)} key={
                        v.id_especialidad
                      }
                        value={
                          v.id_especialidad
                        }>
                        {
                          v.especialidad_name
                        }</option>)
                    }                  
                    <option value={-1}>No lo sé</option>
                </select>
                
                <div className="mt-3 mb-3">
                  <p className="texto_especialidad">

                  {
                   valueEspecialidad === -1  ? "" : especialidades[valueEspecialidad-1].short_description
                }
                  </p>
                </div>
              
                {
                    errors?.especialidad?.type === "required" && (
                      <p className="warning-message" >
                        {
                          errors.especialidad.message
                        }</p>
                    )
                  }              
              </div>
            </div>
          </div>
          <div className="row g-0 d-flex">
            <div className="col-sm-6 m-auto">
              <div className="form-group">
                <label htmlFor="lugar" className="label-style">*Lugar</label>
                <select id="lugar" className="form-control rounded-0 border-0 border-end border-bottom productos-select"
                  {...register("lugar", {
                    required: {
                      value: true,
                      message: "Este campo es requerido."
                    }
                  })}
                  isClearable={true}

                >
                  <option></option>
                  {
                    lugares.map(v => <option key={
                      v.id_state
                    }
                      value={
                        v.id_state
                      }>
                      {
                        v.name
                      }</option>)
                  }
                </select>

                {
                    errors?.lugar?.type === "required" && (
                      <p className="warning-message">
                        {
                          errors.lugar.message
                        }</p>
                    )
                  }              
              </div>
            </div>
          </div>
          <div className="row g-0 d-flex">
            <div className="col-sm-6 m-auto">
              <div className="form-group">
                <label htmlFor="costo" className="label-style">*Rango de costo</label>
                <select id="costo" className="form-control rounded-0 border-0 border-end border-bottom productos-select"
                  {...register("costo", {
                    required: {
                      value: true,
                      message: "Este campo es requerido."
                    }
                  })}
                  isClearable={true}

                >
                  <option></option>

                    {
                      costos.map(v => <option key={
                        v.id_rango
                      }
                        value={
                          v.id_rango
                        }>
                        {
                          v.rango
                        }</option>)
                    }                  
                </select>
                {
                    errors?.costo?.type === "required" && (
                      <p className="warning-message">
                        {
                          errors.costo.message
                        }</p>
                    )
                  }              
              </div>
            </div>
          </div>
          <div className="row g-0 d-flex">
            <div className="col-sm-6 m-auto">
              <div className="form-group">
                <label htmlFor="description" className="label-style">Descripción del problema</label>
                <textarea id="description" className="text-area-style"
                {...register("problem_description", {
                  required: {
                    value: boolDescription,
                    message: "Este campo es requerido"
                  },
                })}
                ></textarea>

                {
                    errors?.problem_description?.type === "required" && (
                      <p className="warning-message">
                        {
                          errors.problem_description.message
                        }</p>
                    )
                  }              
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-sm-6 m-auto">
              <div className='form-group w-100'>
                <button type='submit' className='btn btn-primary btn-block rounded-0 btn-style'
                  onMouseOver={
                    (e) => {
                      e.target.style.border = '1px solid #c0c0c0';
                      e.target.style.color = '#c0c0c0';
                    }
                  }
                  onMouseOut={
                    (e) => {
                      e.target.style.border = '1px solid #007bff';
                      e.target.style.color = '#007bff';
                    }
                  }
                  onClick={handleColorWarning}>Finalizar</button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Footer oscuro={true}/>
    </>
  )
}
