import React, { useState, useRef, useEffect } from 'react'
import "../../styles/AddLawer2Styles.css"
import axios from "axios";
import { getUrl } from '../../ApiCall/GetUrl';


import Select from 'react-select'

export default function Especialidades( { setEpecialPrecio}) { // Establece la especialidad y el precio
  const [espec, setEspecialidadesArr] = useState([]);
  const [presciosArr, setPreciosArr] = useState([]);

  const [espPrecio,setEspPrecio] = useState( null )

  const [options,setOptions] = useState( [])

  //console.log( "Solicitando especialidades ...")

  function onEspChanged( val )
  {

    console.log( "onEspChanged")

    if ( espPrecio === null )
    {
      var obj = {
        especialidad : val ,

        precio : null 

      }

      setEspPrecio( obj )
      setEpecialPrecio( obj )
    }
    else{
      obj = espPrecio ;

      obj.especialidad = val ;

      setEspPrecio( obj ) // Hacemos la actualizacion
      setEpecialPrecio( obj )
    }
  }

  function onPrecioChanged( val )
  {
    if ( espPrecio === null )
    {
      var obj = {
        especialidad : null ,

        precio : val  

      }

      setEspPrecio( obj )
    }
    else{
      obj = espPrecio ;

      obj.precio = val ;

      setEspPrecio( obj ) // Hacemos la actualizacion
    }
  }

  useEffect(() => {
    
    
      axios.get(getUrl() + "get_lawyer_precios").then(response => {
        setPreciosArr(response.data);
        console.log("Precios Este es el response.data: ", response.data);
        getEspecialidades()
      }).catch(err => {
        setPreciosArr(["Hubo un error al traer los datos"]); 
      })
    


    

  } , [])

  function getEspecialidades()
  {
      axios.get(getUrl() + "get_lawyer_especialidades").then(response => {
        console.log("Especialidades Este es el response.data: ", response.data);

        var arr =[]

        for ( var i = 0 ; i < response.data.length ; i++ )
          {
              var obj = {
  
                  value : response.data[i].id_especialidad ,
                  label : response.data[i].especialidad_name
              }
  
              console.log( "onjeto = " , obj )
  
              arr.push( obj ) ;
          }


          setOptions( arr )


        //setEspecialidadesArr( response.data);
        
      }).catch(err => {
        console.log( "Eerror en la especialidad")
        console.log( err )
        //setEspecialidadesArr(["Hubo un error al traer la información"]);
      })
  }

  return (
    <div className='row g-0 mt-4'>

      <div className='col-sm-6'>
        <div className="form-group  "   >
          <label className={"estilo_label"} htmlFor="especialidades">¿Cual(es) son tus especialidades? </label>
          <Select options={options} isMulti={true} onChange={onEspChanged}></Select>
          {/*<select placeholder="" type="text" onChange={(val) => onEspChanged( val )} 
            className={"form-control border-0 border-bottom estilo_input"} id="especialidades" title='Nombre de Abogado'

          >
          <option></option>
          {espec.map(v => 
              <option key={v.id_especialidad} value={v.id_especialidad}>{v.especialidad_name}</option>
              
            )}
          </select>*/} 

        </div>
      </div>

      <div className='col-sm-6'>
        <div className="form-group"   >
          <label className={"estilo_label derecho"} style={{ paddingLeft: "22px" }} htmlFor="precio">¿Cuál es el precio promedio de tu hora?</label>

          
          <select placeholder="" type="email" onChange={onPrecioChanged}
            className={"form-control  border-0 border-bottom border-start estilo_input derecho"} id="precio" title='Nombre de Abogado'
          >
            
            {presciosArr.map(v => 
              <option value={v.id_rango} key={v.id_rango}>{v.rango}</option>
            )}
          </select> 

        </div>
      </div>

    </div>

  )
}
